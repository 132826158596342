.home {
  .sslider3 {
    display: flex;
    justify-content: center;
    margin-bottom: -35px;
    .wrap {
      max-width: 1200px;
      @media (max-width: 1200px) {
        max-width: 100%;
      }
    }
  }
}
.footer-bottom {
  @media (max-width: 768px) {
    height: 300px;
  }
  .footer-bottom-wraper {
    .middleCol {
      display: flex;
      flex-direction: column;
      align-items: center;
      @media (max-width: 768px) {
        margin-top: 10px;
      }
      .socUl {
        list-style-type: none;
        padding-left: 0px;
        display: flex;
        li {
          padding: 5px;
          a {
            color: #000;
            background-color: #fff;
            padding: 3px;
            border-radius: 50px;
            width: 30px;
            height: 30px;
            display: flex;
            align-items: center;
            justify-content: center;
            &:hover {
              text-decoration: none;
              background-color: #dfdfdf;
            }
          }
        }
      }
    }
  }
}